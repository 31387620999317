<template>
  <v-app>
    <navbar />
    <!-- <v-main :class="isHome ? 'home-background' : ''"> -->
    <v-main>
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <footerbar />
  </v-app>
</template>

<script>
import Navbar from './components/Nav.vue'
import Footerbar from '@/components/Footerbar.vue'

export default {
  name: 'App',

  components: {
    Navbar,
    Footerbar,
  },

  data: () => ({
    //
  }),
  computed: {
    isHome() {
      const path = this.$route.path
      if (path == '/') return true
      else return false
    },
  },
}
</script>
<style lang="scss">
/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
  // transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
.v-main.home-background {
  background-color: #29526b;
  // padding-top: 0 !important;
}
.v-main {
  padding-top: 60px !important;
}
// buttons
.v-application a {
  text-decoration: none;
  // color: inherit !important;
}
.v-application .body-1,
.v-application .body-2,
.v-application .caption {
  letter-spacing: 0 !important;
}
.v-btn {
  // font-weight: normal;
  letter-spacing: 0;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: none;
}
.theme--light.v-btn {
  color: rgba(0, 0, 0, 0.7);
}
.v-btn.primary-btn,
.v-chip.primary-btn,
.router-link-active > button {
  background-color: #29526b !important;
  // background-color: #394958 !important;
}
// .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
//   background-color: #eeeeee !important;
// }
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.8rem !important;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(0, 0, 0, 0.25) !important;
}
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card .v-card__subtitle {
  color: #ffffff !important;
}
.clear-border td {
  border-bottom-color: #ffffff !important;
}
// type for chip
.right-edge {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.left-edge {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
tr.atop td {
  padding-top: 3px !important;
  vertical-align: top;
}
</style>
