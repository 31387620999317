<template>
  <nav>
    <v-app-bar app elevate-on-scroll color="white" height="70">
      <div class="d-flex align-center" id="corner-wrapper">
        <router-link to="/" class="text-decoration-none">
          <v-img
            class="mx-2 mt-1"
            src="../assets/wildwood-logo@2x.png"
            max-height="60"
            max-width="60"
            contain
          ></v-img>
        </router-link>
      </div>
      <div class="title-side">
        <v-toolbar-title v-if="!isMobile" class="font-weight-regular">
          Neighborhood Directory
        </v-toolbar-title>
        <!-- <v-toolbar-title
          v-if="!isMobile"
          class="font-weight-light grey--text text--grey-lighten-2"
        >
          Neighborhood Directory
        </v-toolbar-title> -->
      </div>
      <v-chip v-if="isAdmin" color="#5cbbf6" text-color="white" class="ml-2"
        >Admin</v-chip
      >

      <v-spacer></v-spacer>
      <div v-if="isMobile">
        <v-menu>
          <!-- <v-menu class="hidden-md-and-up"> -->
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <vue-fontawesome :icon="['far', 'bars']" />
            </v-btn>
          </template>
          <v-list v-if="isLoggedIn">
            <v-list-item
              v-if="isDev"
              tag="router-link"
              :to="{ path: '/users' }"
            >
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item>
            <v-list-item tag="router-link" :to="{ path: '/properties' }">
              <v-list-item-title>Properties</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item tag="router-link" :to="{ path: '/login' }">
              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else>
        <div v-if="isLoggedIn">
          <router-link to="/users" v-if="isDev">
            <v-btn text :class="navStyle">Users</v-btn>
          </router-link>
          <router-link to="/properties">
            <v-btn text>Properties</v-btn>
          </router-link>
          <router-link v-if="myProperty" :to="'/property/' + myProperty">
            <v-btn text>My Property</v-btn>
          </router-link>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" class="ml-2">
                <vue-fontawesome :icon="['fas', 'user']" class="mr-2" />{{
                  userName
                }}<vue-fontawesome class="ml-2" :icon="['far', 'chevron-down']"
              /></v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="logout">
                <v-list-item-title
                  ><vue-fontawesome
                    :icon="['fas', 'sign-out-alt']"
                    class="mr-2"
                  />Logout</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <router-link to="/login">
            <v-btn text :class="navStyle"
              ><vue-fontawesome
                pull="left"
                :icon="['fas', 'sign-in-alt']"
              ></vue-fontawesome
              >Sign In</v-btn
            >
          </router-link>
        </div>
      </div>
    </v-app-bar>
  </nav>
</template>
<script>
export default {
  name: 'Navbar',
  props: {
    transparentNav: Boolean,
  },
  data() {
    return {
      headerImage: '',
      agencyLoading: true,
      drawer: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn || false
    },
    isAdmin() {
      return this.$store.state.user.isAdmin || false
    },
    isDev() {
      return this.$store.state.user.isDev || false
    },
    myProperty() {
      return this.$store.state.user.property || null
    },
    userName() {
      // return 'Dmitri'
      return (
        this.$store.state.user.firstName + ' ' + this.$store.state.user.lastName
      )
    },
    navStyle() {
      return this.transparentNav === true ? 'mr-2' : 'mr-2'
    },
  },
  mounted() {},
  methods: {
    async logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login').catch((err) => {
          console.log(err)
        })
      })
    },
    async setAgency(index) {
      if (this.isLoggedIn) {
        // this.agencyLoading = true
        const agency = {
          id: this.agencies[index].id,
          name: this.agencies[index].name,
          abbr: this.agencies[index].abbr,
        }
        this.$store.dispatch('setAgency', { agency })
        this.drawer = false
        // setTimeout(() => {
        //   // this.agencyLoading = false
        // }, 5000)
        // this.agencyLoading = false
      }
      // console.log(agency)
    },
  },
}
</script>
<style lang="scss" scoped>
#top-height,
.v-toolbar__content {
  height: 70px !important;
}
.active {
  color: #af1e2c !important;
  // color: #ffffff !important;
  // background-color: #af1e2c;
}
.router-link-active > button {
  // color: #af1e2c !important;
  color: #ffffff !important;
  background-color: #444444;
}
.title-side {
  border-left: 1px solid #dddddd;
  padding-left: 1rem;
  margin-left: 0.5rem;
}
#corner-wrapper {
  position: relative;
}
#agency-portal {
  position: absolute;
  width: 120px;
  top: 38px;
  left: 90px;
  color: #999;
  font-size: 0.8rem;
}
</style>
