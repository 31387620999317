import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSignInAlt,
  faSignOutAlt,
  faKey,
  faBuilding,
  faPencilAlt,
  faBoxOpen,
  faClock,
  faCog,
  faHome,
  faPlusCircle,
  faPlane,
  faExclamationCircle,
  faTruck,
  faHotel,
  faMoneyCheckAlt,
  faPeopleArrows,
  faCalendarDay,
  faEnvelope,
  faCalendarAlt,
  faPhoneAlt,
  faUser,
  faLock,
  faCheck,
  faImage,
  faFilePdf,
  faFile,
  faCheckSquare,
  faSlash,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faCalculator,
  faCreditCard,
  faCaretDown,
  faCaretUp,
  faUpload,
  faPlaneArrival,
  faPlaneDeparture,
  faTag,
  faQuestionCircle,
  faUserCircle,
  faUserPlus,
  faEye,
  faTrash,
  faEyeSlash,
  faThumbsUp,
  faPortrait,
  faUserShield,
  faHandsHelping,
  faChild,
  faPaw,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faSignInAlt,
  faSignOutAlt,
  faKey,
  faBuilding,
  faPencilAlt,
  faPhoneAlt,
  faEnvelope,
  faClock,
  faCog,
  faPlusCircle,
  faPlane,
  faPortrait,
  faTruck,
  faHotel,
  faMoneyCheckAlt,
  faPeopleArrows,
  faCalendarDay,
  faTag,
  faHome,
  faUser,
  faUserCircle,
  faCalendarAlt,
  faBoxOpen,
  faImage,
  faFilePdf,
  faFile,
  faLock,
  faCheck,
  faCheckSquare,
  faSlash,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faCalculator,
  faCreditCard,
  faCaretDown,
  faCaretUp,
  faUpload,
  faPlaneArrival,
  faPlaneDeparture,
  faQuestionCircle,
  faUserPlus,
  faThumbsUp,
  faEye,
  faEyeSlash,
  faUserShield,
  faTrash,
  faHandsHelping,
  faChild,
  faPaw,
  faDollarSign
)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCheck as farCheck,
  faBars,
  faEllipsisH,
  faChevronDown,
  faChevronRight,
  faSearch,
  faPlus,
  faTimes,
  faBan as farBan,
  faVenusMars,
  faMinus,
} from '@fortawesome/pro-regular-svg-icons'
library.add(
  farCheck,
  faBars,
  faEllipsisH,
  faChevronDown,
  faChevronRight,
  faSearch,
  faPlus,
  faTimes,
  farBan,
  faVenusMars,
  faMinus
)
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons'
library.add(faFacebookSquare, faTwitterSquare, faLinkedin, faYoutubeSquare)

Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.use(Vuetify)

export default new Vuetify({})
