import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/login',
    component: () => import('@/views/Login'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/lookup',
    component: () => import('@/views/Lookup'),
    meta: {
      title: 'User Lookup',
    },
  },
  {
    path: '/reset-password',
    component: () => import('@/views/ResetPassword'),
    meta: {
      title: 'Reset Password',
    },
  },
  // {
  //   path: '/users',
  //   component: () => import('@/views/Users'),
  //   meta: {
  //     title: 'Users',
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/properties',
    component: () => import('@/views/Properties'),
    meta: {
      title: 'Properties',
      requiresAuth: true,
    },
  },
  {
    path: '/property/:id',
    component: () => import('@/views/Property'),
    meta: {
      title: 'Property Detail',
      requiresAuth: true,
    },
  },
  // {
  //   path: '/participation/:id',
  //   component: () => import('@/views/Participation'),
  //   meta: {
  //     title: 'Participation',
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/new-participation/citizen/:id',
  //   component: () => import('@/views/NewParticipation'),
  //   meta: {
  //     title: 'New Participation',
  //     requiresAuth: true,
  //   },
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = `Wildwood Directory - ${to.meta.title}`
  next()
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if (localStorage.getItem('bearer') == null) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
