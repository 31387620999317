<template>
  <v-footer dark paddless id="footer" color="#29526b" class="pt-6">
    <v-container>
      <v-row no-gutters>
        <v-col cols="auto">
          <!-- <v-img
            max-width="180"
            src="../assets/reentry-white@2x.png"
            class="mb-2 mr-4"
          ></v-img> -->
        </v-col>
        <v-col cols="12" class="text-center">
          <p class="address">
            <vue-fontawesome :icon="['fas', 'question-circle']" class="mr-0" />
            Any questions? Contact
            <a href="mailto:michael@9degrees.com">Michael Layne</a> or
            <a href="mailto:rachelfarmer@beacham.com">Rachel Farmer</a>
          </p>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-divider></v-divider>
          <div class="pt-4">
            &copy; {{ new Date().getFullYear() }} Wildwood Civic Association.
            Confidential and Proprietary information. [mayreau].
          </div>
          <!-- <div class="pt-4">
            &copy; {{ new Date().getFullYear() }} Applied Research Services,
            Inc. All rights reserved.
          </div> -->
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footerbar',
  data() {
    return {
      linksFirst: [
        {
          title: 'About SRS',
          url: '#',
          target: true,
        },
        {
          title: 'Locations',
          url: '#',
          target: true,
        },
        {
          title: 'Careers',
          url: '#',
          target: true,
        },
        {
          title: 'Contact Us',
          url: '#',
          target: true,
        },
      ],
      linksSecond: [
        {
          title: 'Events',
          url: '#',
          target: true,
        },
        {
          title: 'News',
          url: '#',
          target: true,
        },
        {
          title: 'Privacy Policy',
          url: '#',
          target: true,
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
#footer {
  font-size: 0.875rem;
  padding: 1rem;
  color: #aaaaaa;
}
.address {
  color: #cccccc;
  line-height: 1.3rem;
}
.address b {
  color: #aaaaaa;
}
.footer-links {
  line-height: 1.5rem;
  list-style-type: none;
}
.footer-links li a,
.address a {
  color: #bbbbbb;
  border-bottom: 1px dotted #bbbbbb;
}
.footer-links li a:hover,
.address a:hover {
  color: #f3f3f3;
}
</style>
