import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// const loginpath = window.location.pathname
// console.log(loginpath)

// const instance = axios.create({
//   baseURL: process.env.VUE_APP_FM_DATA_API,
//   timeout: 1000,
//   headers: {'X-Custom-Header': 'foobar'}
// });

export default new Vuex.Store({
  plugins: [createPersistedState()],
  components: {
    axios,
  },
  state: {
    appName: 'wildwoodDirectory',
    currentYear: Number(process.env.VUE_APP_CURRENT_YEAR),
    isLoggedIn: false,
    user: {},
  },
  mutations: {
    SET_REDIRECT_PATH(state, path) {
      state.redirectPath = path
    },
    // AUTH_REQUEST(state) {
    //   state.status = 'loading'
    // },
    AUTH_SUCCESS(state, user) {
      state.appName = 'wildwoodDirectory'
      state.status = 'success'
      state.currentYear = Number(process.env.VUE_APP_CURRENT_YEAR)
      state.isLoggedIn = true
      state.redirectPath = ''
      // state.isSandbox = true
      // state.token = token
      state.user = user.user
    },
    LOGOUT(state) {
      state.isLoggedIn = false
      state.user = ''
      state.currentYear = ''
    },
  },
  actions: {
    login({ commit }, user) {
      commit('AUTH_SUCCESS', user)
    },
    logout({ commit }) {
      localStorage.removeItem('token')
      commit('LOGOUT')
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.isLoggedIn,
  },
})
